body,
html {
    margin: 0;
    padding: 0;

    * {
        box-sizing: border-box;
    }
}

html {
    scroll-behavior: smooth;
}

html,
body {
    height: 100%;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
}

img {
    max-width: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.center {
    text-align: center;
}

.container {
    width: 90%;
    margin: 0 auto;
}

.flex {
    display: flex;

    .flex-1 {
        flex: 1;
    }

    .flex-2 {
        flex: 2;
    }

    .flex-3 {
        flex: 3;
    }
}

.grid {
    display: grid;
    row-gap: 25px;
    column-gap: 25px;

    &.two-cols {
        grid-template-columns: 1fr 1fr;
    }

    &.three-cols {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 56em) {
    .flex {
        display: block;
    }

    .grid {
        display: block;
    }
}